.plan-add-header-v2{
  background-color: #f5f5f5 !important;
  display: flex;
  justify-content: flex-end;
  position: relative;
  padding: 10px 5px;
  min-height: 65px;
  margin: 0 20px 3px 20px;
  border-radius: 8px;
  .plan-add-header-info{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0  10px;
    .name{
      font-family: YekanBold;
    }
    .mobile{
      font-family: YekanRegular;
      font-size: 14px;
    }
  }
  .plan-add-header-image{
    position: absolute;
    left: 20px;
    top: -10px;
    width: 80px;
    height: 80px;
    border-radius:100px;
    img{
      width: 100%;
      height: 100%;
      border-radius: 100px;
      object-fit: cover;
      border:2px solid white;
    }
  }
}

.plan-add-header-type{
  background-color: #F5F5F5;
  display: flex;
  justify-content: flex-end;
  margin-left: 50px;
  span:active{
    opacity: 0.3
  }
  span{
    font-family: YekanRegular;
    font-size: 12px;
    padding: 5px;
    cursor: pointer;
  }
  select{
    border: none;
    background-color: transparent;
    margin: 5px 15px 5px 5px;
    outline: none;
    font-family: YekanRegular;
    font-size: 12px;
  }
}

.plan-add-tabs{
  display: flex;
  align-items: center;
  margin: 30px 30px 0 30px;
  .plan-add-tabs-item-v2:active{
    opacity: 0.3;
  }
  .plan-add-tabs-item-v2{
    cursor: pointer;
    flex:1;
    border:1px solid #DBDBDB;
    background-color: #FCFCFC;
    margin: 0 5px;
    display: flex;
    align-items: center;
    height: 30px;
    border-radius: 5px;
    justify-content: center;

    span{
      font-family: YekanMedium;
      color: #434343;
      margin: 0  5px;
    }
  }
}
.plan-month{
  display: flex;
  justify-content: flex-end;
  span{
    color: white;
    font-family: YekanMedium;
    margin: 2px 8px;
    font-size: 12px
  }
}
.bar-wrapper{
  display: flex;
  align-items:center;
}
.plan-bar{
  z-index:1;
  margin:0 30px;
  position: relative;

}
.plan-sec{
  margin: 10px 5px;
}
.plan-bar-list{
  padding:14px 16px;
  background-color: #FCFCFC !important;
  border: 1px solid #E2E2E2;
  border-radius: 5px;
  .plan-bar-item{
    position: relative;
    width:60px;
    background-position: center;
    background-repeat: no-repeat;
    font-family: YekanRegular;
    cursor: pointer;
    color: #afafaf;
    text-align: center;
    display: flex;
    flex-direction: column;
  }
  .plan-bar-item:active{
    opacity: 0.3;
  }
  .plan-bar-item-deactive{
    position: relative;
    height:60px;
    width:60px;
    border-radius: 60px;
    background-position: center;
    //background-size: contain;
    background-repeat: no-repeat;
    border:3px solid #dcdbf1;
    filter:saturate(0)
  }
}

.plan-add-fix-footer-wr{
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 4;
  .plan-add-fix-footer-sec{
    z-index:4;
    background-color: white;
    display: flex;
    position: relative;
    padding: 10px 0px;
    height: 50px;
    direction: rtl;
    .plan-add-fix-footer-btns{
      display: flex;
      align-items: flex-start;
      margin: 0 20px;
      span{
        font-family: YekanRegular;
        color: white;
        cursor: pointer;
        border-radius: 4px;
        padding: 2px 10px;
        margin: 0 5px;
      }
      span:active{
        opacity: 0.4;
      }
      .save-btn{
        background-color: #605BFF;
      }
      .save-send-btn{
        background-color: #8D89FD;
      }
    }

    .plan-add-fix-footer-mic-on:active{
      opacity: 0.3;
    }
    .plan-add-fix-footer-mic-on{
      position: absolute;
      left: 15px;
      top: -25px;
      background-color: #585858;
      border-radius: 50px;
      padding: 10px 15px;
      cursor: pointer;
    }


    .plan-add-fix-footer-mic{
      position: absolute;
      left: 15px;
      top: -25px;
      background-color: white;
      border-radius: 50px;
      padding: 10px 15px;
      cursor: pointer;
    }
    .plan-add-fix-footer-mic:active{
      opacity: 0.3;
    }

  }
}


.plan-create-list{
  margin: 20px 0px 10px 0px !important;
  padding-bottom: 100px;
}

.bullet-sec{
  span{
    margin: 0 5px !important;
  }
}


.plan-card-diet{
  margin-top: 15px;
  display: flex;
  align-items: center;

  .plan-exercise-card-main{
    position: relative;
    background-color: white;
    border-radius: 5px;
    flex: 10;
    display: flex;
    flex-direction: column;
    .plan-exercise-card-main-header:active{
      opacity: 0.3;
    }
    .plan-exercise-card-main-header{
      display: flex;
      cursor: pointer;
      background-color:#D9D9D9;
      justify-content: space-between;
      padding: 8px 15px;
      border-radius: 5px 5px 0 0;
      .plan-exercise-card-main-header-delete:active{
        opacity: 0.3;
      }
      .plan-exercise-card-main-header-delete{
        cursor: pointer;
        span{
          color: white;
        }
      }
      .plan-exercise-card-main-header-name{
        font-family: YekanBold;
        span{
          margin: 0 4px !important;
        }
      }
    }
    .plan-exercise-card-main-body{
      display: flex;
      min-height: 80px;
      align-items: center;
      .plan-exercise-card-main-body-child{
        flex:9;
        display: flex;
        //background-color:red;
        justify-content:space-around;
        .plan-exercise-card-main-body-child-item:active{
          opacity:0.3
        }
        .plan-exercise-card-main-body-child-item{
          cursor: pointer;
          display: flex;
          flex-direction:column;
          align-items:center;
          .plan-exercise-card-main-body-child-item-val{
            font-size: 18px;
            font-weight: bold;
          }
          .plan-exercise-card-main-body-child-item-label{
            font-family:YekanBold;
            font-size:10px;
            color: #bdbdbd
          }
        }
      }
      .plan-exercise-card-main-body-video:active{
        opacity: 0.3;
      }
      .plan-exercise-card-main-body-video{
        cursor: pointer;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        flex:3;
        background-color:#F3F3F3;
        display: flex;
        align-items:center;
        justify-content:center;
        margin: 10px;
        border-radius: 5px;
        padding: 20px
      }
    }
  }


  .plan-card-number-line{
    position: absolute;
    height: 125px !important;
    width: 5px;
    background-color:#FFCE31;
    top: 26px;
  }
  .plan-card-number{
    position: relative;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: YekanBold;
    border-radius: 30px;
    background-color: #FFCE31;
    margin: 0 10px;
    font-size: 20px;
  }

}

.plan-card{
  margin-top: 25px !important;
  display: flex;
  align-items: center;

  .plan-exercise-card-main{
    position: relative;
    background-color: white;
    border-radius: 5px;
    flex: 10;
    display: flex;
    flex-direction: column;

    .plan-exercise-card-main-header{
      display: flex;
      background-color:#D9D9D9;
      justify-content: space-between;
      padding: 8px 15px;
      border-radius: 5px 5px 0 0;
      .plan-exercise-card-main-header-delete:active{
        opacity: 0.3;
      }
      .plan-exercise-card-main-header-delete{
        cursor: pointer;
        span{
          color: white;
        }
      }
      .plan-exercise-card-main-header-name{
        font-family: YekanBold;
        span{
          margin: 0 4px !important;
        }
      }
    }
    .plan-exercise-card-main-body{
      display: flex;
      align-items: center;
      .plan-exercise-card-main-body-child{
        flex:9;
        display: flex;
        //background-color:red;
        justify-content:space-around;
        .plan-exercise-card-main-body-child-item:active{
          opacity:0.3
        }
        .plan-exercise-card-main-body-child-item{
          cursor: pointer;
          display: flex;
          flex-direction:column;
          align-items:center;
          .plan-exercise-card-main-body-child-item-val{
            font-size: 18px;
            font-weight: bold;
          }
          .plan-exercise-card-main-body-child-item-label{
            font-family:YekanBold;
            font-size:10px;
            color: #bdbdbd
          }
        }
      }
      .plan-exercise-card-main-body-video:active{
        opacity: 0.3;
      }
      .plan-exercise-card-main-body-video{
        cursor: pointer;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        flex:3;
        background-color:#F3F3F3;
        display: flex;
        align-items:center;
        justify-content:center;
        margin: 10px;
        border-radius: 5px;
        padding: 20px
      }
    }
  }



}

@media screen and (min-width: 800px) {
  .plan-add-fix-footer-wr{
    width: 42vw !important;
  }
  .plan-add-fix-footer-list{
    width: 42vw !important;
  }
}

.plan-add-fix-footer-search{
  background-color:#E9E9E9;
  display: flex;
  padding: 10px 10px 10px 85px;
  position: relative;
  z-index:2;
  .plan-add-fix-footer-search-img:active{
    opacity:0.3
  }
  .plan-add-fix-footer-search-img{
    cursor: pointer;
    background-color: #FAFAFA;
    padding: 15px;
    border-radius: 8px 0 0 8px;
  }
  input{
    padding:4px;
    width: 80%;
    border-radius:0;
    border:none !important;
    font-size: 17px;
    font-family: YekanBold;
    text-align: center;
    direction: rtl;
    outline: none;
  }
}


.plan-add-fix-footer-list{
  //background-color:red;
  position:fixed;
  z-index:1;
  width:100%;
  height:60vh;
  transition: bottom 0.5s ease 0s;
  //bottom: -322px; // 178
}
.filter-active{
  background-color: #8D89FD !important;
}
.plan-add-fix-footer-list-header{
  background-color: #a7aef3;
  display: flex;
  align-items:center;
  justify-content: space-between;
  padding: 10px;
  border-radius:10px 10px 0 0 ;

  .plan-add-fix-footer-list-header-close:active{
    opacity:0.3
  }
  .plan-add-fix-footer-list-header-close{
    font-size:25px;
    color: white;
    cursor:pointer;
  }
  .plan-add-fix-footer-list-header-btn:active{
    opacity: 0.3;
  }
  .plan-add-fix-footer-list-header-btns{
    display: flex;
    align-items:center;
  }
  .plan-add-fix-footer-list-header-btn{
    background-color: #dfdef8;
    color: white;
    width: 100px;
    margin: 4px 10px;
    cursor: pointer;
    font-family: YekanMedium;
    text-align: center;
    border-radius:4px;
    padding: 4px 8px;
  }

}

.plan-add-fix-footer-list-body{
  overflow-y: scroll;
  height:inherit;
  background-color: #ffffff;
}

.plan-add-fix-footer-list-main{
  display: flex;
  flex-wrap: wrap;
  .plan-add-fix-footer-list-body-item:active{
    opacity: 0.3;
  }
  .plan-add-fix-footer-list-body-item{
    flex-basis: 47%;
    box-sizing: border-box;
    cursor: pointer;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin:4px;
    border-radius: 8px;
    height: 150px;
    position: relative;
    img{
      width: 100%;
    }
    span{
      background-color: white;
      width: 100%;
      position: absolute;
      bottom: 0;
      border-radius: 0 0 8px 8px;
      font-family: YekanRegular;
      text-align: right;
      direction:rtl;
      padding: 10px;
      opacity: 0.8
    }
  }
}



.num-drawer{
  .num-drawer-header{
    border-bottom: 1px solid lightgray;
    margin: 2px 10px;
    text-align: center;
    padding: 15px;
    span{
      font-family: YekanBold;

    }
  }
  .num-drawer-list{
    .num-drawer-item:active{
      opacity: 0.3
    }
    .num-drawer-item{
      cursor:pointer;
      text-align: center;
      direction: rtl;
      background-color:lightgray;
      border-radius: 10px;
      margin:10px;
      padding: 10px;
      span{
        font-family: YekanRegular;
      }
    }
  }
}

.option-drawer{

  .active-option{
    background-color:#03A9F4  !important;
  }
  .option-drawer-item:active{
    opacity: 0.3;
  }
  .option-drawer-item{
    cursor:pointer;
    background-color:lightgray;
    border-radius: 10px;
    margin: 15px 10px;
    padding: 15px;
    text-align: center;
    display: flex;
    flex-direction: column;
    span{
      font-family: YekanBold;
      text-align: center;
    }
  }

}

.num-drawer--float-header:active{
  opacity:0.3
}
.num-drawer--float-header{
  position: absolute;
  top:0;
  background-color:#8A86FE;
  width: 100%;
  display: flex;
  padding: 10px;
  text-align: center;
  justify-content: center;
  cursor: pointer;
  span{
    font-family: YekanBold !important;
    text-align: center !important;
    color: white;
  }
}

.start-drawer{
  display: flex;
  flex-direction: column;
  .start-drawer-form{
    display: flex;
    justify-content: space-around;
    background-color:#b4b2f3;
    padding: 10px;
    align-items: center;
    .start-drawer-btn:active{
      opacity:0.3
    }
    .start-drawer-btn{
      background-color:#673AB7;
      width: 80px;
      border-radius:5px;
      padding: 3px 0;
      cursor: pointer;
      color: white;
      text-align: center;
      span{
        font-family: YekanBold;
        font-size: 13px;
      }
    }
    .start-drawer-items{
      display: flex;
      align-items: center;
      .start-drawer-item{
        margin: 2px 4px;
        span{
          font-family: YekanRegular;
        }
        select{
          width: 60px;
          text-align: center;
          direction: rtl;
          padding: 4px 0;
          border-radius:8px;
          border: 1px solid lightgrey;
          font-family: YekanRegular;
          font-size: 12px;
        }
      }
    }

  }

  .start-drawer-warn{
    background-color: #f7c8b8;
    padding: 5px 15px;
    p{
      font-family: YekanMedium;
      font-size: 13px;
      text-align: right;
    }
  }

}

.active-part{
  background-color: #ffd383  !important;
}

.plan-exercise-card-main-body-childes{
  display: flex;
  flex-direction: column;
  width: 100%;
  .plan-exercise-card-main-body-child{
    display: flex;
    align-items: center;
    justify-content: flex-end !important;
    padding: 10px;
    span{
      margin: 0 30px;
      font-family: YekanRegular;
    }
    img:active{
      opacity: 0.3;
    }
    img{
      background-color: #8D89FD;
      padding: 6px 4px 8px 4px;
      border-radius: 5px;
      cursor: pointer;
    }
  }
}

.save-drawer{
  .save-drawer-header{
    display: flex;
    justify-content: center;
    padding: 10px;
    background-color: #8D89FD;
    span{
      font-family: YekanRegular;
      color: white;
      text-align: center;
    }
  }

  .save-drawer-body{
    .save-drawer-form{
      .save-drawer-form-item{
        .save-drawer-form-item-label{
          display: flex;
          justify-content: center;
          padding: 10px;
          margin: 10px;
          span{
            font-family: YekanRegular;
            margin: 0 10px;
          }
          input{
            width: 25px;
            height: 25px;
          }
        }
        .save-drawer-form-item-input{
          display: flex;
          justify-content: flex-end;
          margin-bottom: 10px;
          input{
            direction: rtl;
            width: 100%;
            border: 1px solid lightgrey;
            border-radius: 5px;
            font-family: YekanRegular;
            text-align: center;
            padding: 10px;
          }
        }
      }
    }
  }

  .save-drawer-footer{
    display: flex;
    align-items: center;
    justify-content: center;
    .saveAndSend-btn{
      background-color: #8D89FD;
    }
    .save-btn{
      background-color: #aa8dd7;
    }
    span:active{
      opacity: 0.3;
    }
    span{
      cursor: pointer;
      text-align: center;
      font-family: YekanRegular;
      color: white;
      width: 100px;
      margin: 10px;
      padding: 4px;
      border-radius: 5px;
    }

  }

}

.lib-sec{
  span{
    font-family: YekanRegular;
    color: white;
    background-color: #8D89FD;
    border-radius: 5px;
    padding: 2px 20px;
    margin: 0 15px;
  }
}
.lib-title{
  border-bottom: 1px solid lightgray;
  margin: 2px 10px;
  text-align: center;
  padding: 15px;
  font-family: YekanBold;
}


.plan-card-trainer{
  //background-color: lightgrey;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 20px;
  padding: 5px;
  .trainer-name{
    font-family: YekanRegular;
  }
  border-bottom: 1px solid #f9f9f9;

}

.save-drawer-form-library{
  background-color: #eae9e9;
  border-radius: 5px;
  margin: 10px 20px;

}

.save-drawer-form-delivery{
  background-color: #f9f9f9;
  border-radius: 5px;
  padding: 10px;
  margin: 5px 20px;
  .save-drawer-form-delivery-item-title{
    display: flex;
    justify-content: center;
    font-family: YekanRegular;
    margin-bottom: 5px;
  }
  .save-drawer-form-delivery-item{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .save-drawer-form-delivery-item-label{
      font-family:YekanRegular
    }
    .save-drawer-form-delivery-item-mobile{
      font-family:YekanRegular
    }
    .save-drawer-form-delivery-item-btn:active{
      opacity:0.3
    }
    .save-drawer-form-delivery-item-btn{
      background-color: #4CAF50;
      border-radius: 4px;
      padding: 4px;
      cursor: pointer;
      font-family: YekanRegular;
      margin: 0 5px;
      flex:2;
      color: white;
      text-align: center;
    }
    input[type=text]{
      flex:10;
      border-radius: 5px;
      border:none;
      padding: 6px
    }
  }

}


.lib-row{
  display: flex;
  margin: 10px;
  background-color: #9191eb;
  border-radius: 8px;
  .lib-item-delete{
    flex: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e6e1ff;
    border-radius: 8px 0 0 8px;
  }

  .lib-list{
    margin: 0 20px;
  }
  .lib-item{
    flex: 10;
    font-family: YekanRegular;
    cursor: pointer;
    text-align: center;
    direction: rtl;
    border-radius: 10px;
    margin: 10px;
    color: white;
  }
}

.plan-exercise-card-main-body-video-upload{
  flex:3;
  display: flex;
  margin: 10px;
  div:active{
    opacity:0.3
  }
  div{
    padding: 10px;
    flex:1;
    margin: 0 5px;
    border-radius: 8px;
    cursor: pointer;
  }
  .plan-exercise-card-main-body-video-upload-do{
    background-color: #95c3f6;
  }
  .plan-exercise-card-main-body-video-upload-cancel{
    background-color: #ff7e98;
  }
}

.plan-exercise-card-main-body-video-choose:active{
  opacity: 0.3;
}
.plan-exercise-card-main-body-video-choose{
  cursor: pointer;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  flex:3;
  background-color: #c8d3fd;
  display: flex;
  align-items:center;
  justify-content:center;
  margin: 10px;
  border-radius: 5px;
  padding: 20px;
  span{
    font-family: YekanBold;
  }
}


.pending-upload{
  padding: 14px 40px;
}

.plan-end-time{
  margin: 0 20px !important;
  span{
    font-family: YekanBold !important;
    color: black !important;
  }
}

.plan-exercise-card-main-header-link{

}

.link-on{
  color:white;
  background-color: #7A75FF;
  width: 25px;
  height: 25px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.plan-exercise-card-main-header-actions{
  display: flex;
  align-items: center;
  div:active{
    opacity: 0.3;
  }
  div{
    margin: 0 10px;
    cursor: pointer;
  }
}

.links-menu{
  position: fixed;
  top: 0;
  background-color: #8d89fd;
  right: 100px;
  padding: 10px 0;
  border-radius: 0 0 8px 8px;
  z-index: 10;
  .links-menu-item{
    background-color: white;
    display: flex;
    justify-content: center;
    margin: 5px;
    padding: 4px 15px;
    border-radius: 5px;
    width: 150px;
    position: relative;
    span{
      font-family: YekanBold;
      font-size: 13px;
    }
    img{
      position: absolute;
      right: 8px;
    }
  }
}

.plan-exercise-card-main-header-alternatives{
  position: absolute;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  top: -18px;
  right: 0;
  .active-alter{
    color: #7A75FF !important;
  }
  .plan-exercise-card-main-header-alternatives-item{
    width: 30px;
    background-color: #e2e1ff;
    margin: 0 3px;
    border-radius: 5px 5px 0 0;
    display: flex;
    font-family: YekanBold;
    align-items: center;
    justify-content: center;
  }
}


.plan-card-number-super{
  position: relative;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: YekanBold;
  border-radius: 30px;
  background-color: #faad14;
  margin: 0 10px;
  color:white;
  font-size: 20px;
}
.plan-card-number-line-super{
  position: absolute;
  height: 167px !important;
  width: 5px;
  background-color:#faad14;
  top: 25px;
}

.super-bullet{
  border: 5px solid white;
  border-radius: 5px;
}


.plan-exercise-card-super-header{
  display: flex;
  align-items: center;
  background-color: #faad14;
  border-radius: 5px;
  padding: 8px 0;
  .plan-exercise-card-main-header-super-name{
    flex:3;
    span{
      font-family: YekanBold;
      color: white;
    }
  }
  .plan-exercise-card-main-body-child{
    flex:9;
    display: flex;
    //background-color:red;
    justify-content:space-around;
    .plan-exercise-card-main-body-child-item:active{
      opacity:0.3
    }
    .plan-exercise-card-main-body-child-item{
      cursor: pointer;
      display: flex;
      flex-direction:column;
      align-items:center;
      .plan-exercise-card-main-body-child-item-val{
        font-size: 18px;
        font-weight: bold;
        color: white;
      }
      .plan-exercise-card-main-body-child-item-label{
        font-family:YekanBold;
        font-size:10px;
        color: white;
      }
    }
  }
}

.plan-exercise-card-super-actions{
  .plan-exercise-card-super-actions-item{
    color: white;
    margin: 10px;
  }
}

.actions-margin{
  margin: 2px 0;
}

.tools-list{
  position: absolute;
  display: flex;
  flex-direction: column;
  left: -26px;
  .tools-item{
    margin-top:8px;
    img{
      width: 20px;
      height: 20px;
    }
  }
}


.plan-card-v2{
  margin:25px 30px;
  padding: 10px;
  position: relative;
  background-color: #E2E1FF;
  border: 1px solid #B6B6B6;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  .plan-card-v2-body{
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    textarea{
      outline: none;
      background-color: transparent;
      border:none;
      border-radius: 5px;
      direction: rtl;
      font-family: YekanRegular;
      text-align: right;
      width: 100%;
    }
    .plan-card-v2-body-info{
      display: flex;
      flex-direction: column;
      align-items:flex-end;
      justify-content: flex-end;
      margin:3px 10px;
      width:100%;
      span{
        font-family:YekanRegular;
        direction: rtl;
        color:#8d8d8d
      }
      h6{
        font-family:YekanBold;
        font-size:14px;
        margin: 0;
        direction: rtl;
      }
      h5{
        font-family:YekanBold;
        font-size:13px;
        margin: 0;
        text-align: right;
        word-break: break-all;
      }
      h3{
        font-family:YekanMedium;
        font-size:13px;
        margin: 0;
        text-align: right;
        direction: rtl;
        word-break: break-all;
      }
      p{
        font-family:YekanRegular;
        direction: rtl;
      }
    }
    .plan-card-v2-body-video{
      .plan-card-v2-body-video-card{
        background-color: white;
        border:1px solid #B6B6B6;
        border-radius: 8px;
        padding:15px;
        display: flex;
        align-items:center;
        justify-content:center;
        img{
          width: 30px;
        }
      }
    }
  }
  .plan-card-v2-footer{
    width: 100%;
    border-top: 1px solid white;
    margin-top:10px;
    padding-top:10px;
    .plan-card-v2-footer-item:active{
      opacity:0.3
    }
    .plan-card-v2-footer-item{
      width: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      h6{
        font-family:YekanRegular;
        color: #313131;
        font-size:13px;
      }
      span{
        font-family: YekanHeavy;
        font-size:15px;
      }
    }
  }

}

.selected-queue{
  div{
    border: 4px solid blue;
  }
}

.queue-sec{
  position: absolute;
  right: -30px;
  top: 50%;

.queue-number-super:active{
  opacity: 0.3;
}
  .queue-number-super{
    cursor: pointer;
    background-color: #faad14;
    border-radius:5px;
    padding:4px;
    width:25px;
    height:25px;
    display:flex;
    align-items: center;
    justify-content: center;
    span{
      color:white;
      font-family: YekanMedium;
    }
  }
  .queue-number:active{
    opacity: 0.3;
  }
  .queue-number{
    cursor: pointer;
    background-color: #EFEBEB;
    border: 1px solid  #B6B6B6;
    border-radius:5px;
    padding:4px;
    width:25px;
    height:25px;
    display:flex;
    align-items: center;
    justify-content: center;
    span{
      font-family: YekanHeavy;
    }
  }
}



.plan-card-v2-super {
  margin: 5px 25px;
  padding: 10px;
  position: relative;
  .plan-card-number-super{
    position: absolute;
    right: -35px;
    top: 20%;
  }
}

.plan-card-number-line{
  position: absolute;
  height: 167px;
  width: 5px;
  background-color: #EFEBEB;
  top: 25px;
}
.plan-card-number{
  position: relative;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: YekanBold;
  border-radius: 30px;
  background-color: #EFEBEB;
  margin: 0 10px;
  font-size: 20px;
}

.card-main-header-v2:active{
  opacity: 0.3;
}
.card-main-header-v2{
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  background-color: #f7f7f7;
  padding: 4px 10px;
  border-radius: 6px;
  font-family: 'YekanBold';
}

.selected-video-label{
  color:white;
  border-radius: 4px;
  display: flex;
  background-color: #cfc7ff;
  justify-content: space-between;
  align-items: center;
  .selected-video-label-remove{
    background-color: #8875f9;
    padding: 2px 10px 6px 10px;
    border-radius: 4px 0 0 4px;
  }
  .selected-video-label-text{
    padding: 0 10px;
    span{
      color: #5d5d5d !important;
    }
  }
  .selected-video-label-link{
    padding: 4px 4px;
  }

}

.bullet{
  margin: 0;
  background-color: #a495ff;
  width: 15px;
  height: 15px;
  border-radius: 4px;
}



.meal-desc{
  direction: rtl;
}

.package-name{
  font-family: YekanBold;
}

.package-trainer-name{
  font-family: YekanMedium;
}
.plan-add-header-image-package{
  position: absolute;
  left: 20px;
  top: 14px;
  width: 80px;
  height: 80px;
  border-radius:100px;
  img{
    width: 100%;
    height: 100%;
    border-radius: 100px;
    object-fit: cover;
    border:2px solid white;
  }
}

.plan-add-header-v2-package{
  background-color: #f5f5f5 !important;
  display: flex;
  justify-content: flex-end;
  position: relative;
  padding: 10px 5px;
  min-height: 108px;
  margin: 0 20px 3px 20px;
  border-radius: 8px;
  .plan-add-header-info{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0  10px;
    .name{
      font-family: YekanBold;
    }
    .mobile{
      font-family: YekanRegular;
      font-size: 14px;
    }
  }
  .plan-add-header-image{
    position: absolute;
    left: 20px;
    top: -10px;
    width: 80px;
    height: 80px;
    border-radius:100px;
    img{
      width: 100%;
      height: 100%;
      border-radius: 100px;
      object-fit: cover;
      border:2px solid white;
    }
  }
}

//box-shadow:0px 4px 12px 0px rgb(0 0 0 / 41%)