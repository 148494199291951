/* src/Widget.css */
.widget-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  //z-index: 9999;
}

.widget-button {
  width: 50px;
  height: 50px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 50%;
  font-size: 24px;
  cursor: pointer;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.widget-popup {
  position: absolute;
  bottom: 60px;
  right: 0;
  width: 340px;
  background: white;
  box-shadow: -4px -3px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow-y:scroll;
  height: 80vh;
}
