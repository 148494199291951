.online-shop-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color:#F5F2F2;
  margin: 8px 16px;
  border-radius:4px;
  padding: 8px;
  .online-shop-header-cart{
    position: relative;
    display: flex;
    .online-shop-header-cart-total{
      display: flex;
      align-items: center;
      margin: 0 18px;
    }
    .cart-badge{
      position: absolute;
      background-color: red;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width:25px;
      height:25px;
      border-radius: 12px;
      top: -8px;
      left: 15px;
    }
  }
  span{
    font-family:YekanRegular;
    direction: rtl;
  }
  h5{
    font-family:YekanBold;
    margin: 0;
    font-size:14px
  }
}

.shop-footer-row{
  display: flex;
  justify-content: space-between;
  margin: 8px 24px;
  span{
    font-family:YekanRegular;
    font-size:13px
  }
}

.ant-drawer-title{
  text-align: center;
  font-family: YekanBold;
}


.shop-product-list{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 12px 12px;
  .shop-product-list-item{
    flex: 0 1 48%; /* هر آیتم حدوداً نصف عرض کانتینر را بگیرد */
    margin-bottom: 10px; /* فاصله بین آیتم‌ها */
  }
  .shop-product-list .shop-product-list-item:nth-child(odd):last-child {
    margin-left: auto;
    margin-right: auto;
  }
}

.shop-product-detail{
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center
}
.shop-product-detail-image{
  img{
    width: 100%;
    border-radius:8px
  }
}

.shop-product-detail-price{
  font-family: YekanBold;
  font-size:18px;
  display: flex;
  height: 20px;
  align-items: center;
  margin-top: 15px;
}

.shop-product-detail-label{
  font-family: YekanMedium;
  font-size:18px;
  display: flex;
  height: 20px;
  align-items: center;
  margin-top: 15px;
  color: #2f54eb;
}
.shop-product-detail-discount{
  font-family: YekanRegular;
  color: red;
  text-decoration: line-through;
  display: flex;
  height: 17px;
  align-items: center;
  font-size: 13px;
}

.shop-product-detail-faname{
  font-family: YekanBold;
  color: #535252;
  direction: rtl;
}

.shop-product-detail-enname{
  font-family: YekanMedium;
  color: gray;
}

.shop-product-detail-product-description{
  font-family: YekanMedium;
  color: gray;
  font-size: 13px;
  direction: rtl;
}

.shop-product-detail-owner-description{
  font-family: YekanMedium;
  color: gray;
  font-size: 13px;
  direction: rtl;
}

.shop-product-detail-out-of-stock{
  background-color:#d7d7d7;
  color: white;
  font-family: YekanMedium;
  width: 200px;
  border-radius: 8px;
  padding: 8px;
  display: flex;
  justify-content:center;
  align-items:center;

}

.shop-product-detail-add-btn{
  background-color:#AFAFFD;
  color: white;
  font-family: YekanMedium;
  width: 200px;
  border-radius: 8px;
  padding: 8px;
  display: flex;
  justify-content:center;
  align-items:center;

}

.shop-product-detail-image-placeholder{
  width: 90vw;
  border-radius: 8px;
  height: 250px;
  display: flex;
  align-items:center;
  justify-content:center;
  background-color:lightgray;
  .anticon{
    font-size:35px;
    color: white;
  }
}


.cart-invoice-container{}

.cart-invoice-detail-price{
  background-color:#F5F2F2;
  margin: 12px 24px;
  border-radius:8px;
  padding-bottom: 8px;
}

.cart-invoice-detail-price-item{
  display: flex;
  justify-content:space-between;
  padding:12px;
  border-bottom: 1px solid #E8E1E1;
  margin: 0 12px;
}

.cart-invoice-detail-price-item-label{
  font-family: YekanBold;
}
.cart-invoice-detail-price-item-value{
  font-family: YekanRegular;
}

.cart-invoice-detail-price-item-total{
  display: flex;
  flex-direction: column;
  align-items: center;
  h5{
    font-family: YekanBold;
    font-size: 28px;
    margin: 8px 0;
  }
  span{
    font-family: YekanMedium;
  }
}
.cart-invoice-detail-price-item-see-detail img {
  transition: transform 0.3s ease;
}
.rotate {
  transform: rotate(180deg);
}

.cart-invoice-detail-price-item-see-detail{
  padding-top: 12px;
  padding-bottom: 4px;
  display: flex;
  justify-content: center;
  span{
    font-family: YekanBold;
    font-size:12px;
    color: gray;
  }
}



.cart-invoice-owner-wallet-info{
  background-color:#faad14;
  margin: 12px 24px;
  border-radius:8px;
  padding:12px;
  h5{
    font-family: YekanBold;
    color: white;
    text-align: center;
    font-size: 24px;
  }
  .cart-invoice-owner-pay-info-footer{
    display: flex;
    justify-content: space-between;
    color: white;
    font-family: YekanMedium;
  }
}

.cart-invoice-owner-pay-info{
  background-color:#0E9CFF;
  margin: 12px 24px;
  border-radius:8px;
  padding:12px;
  h5{
    font-family: YekanBold;
    color: white;
    text-align: center;
    font-size: 24px;
  }
  .cart-invoice-owner-pay-info-footer{
    display: flex;
    justify-content: space-between;
    color: white;
    font-family: YekanMedium;
  }
}

.cart-invoice-hint{
  margin: 12px 24px;
  border-radius:8px;
  padding:12px;
  h5{
    font-family: YekanBold;
    direction: rtl;
    font-size: 15px;
  }
  p{
    font-family: YekanRegular;
    direction: rtl;
    font-size: 13px;
  }
}

.cart-invoice-input{
  background-color:#F5F2F2;
  margin: 8px 24px;
  border-radius:8px;
  padding:12px;
  display: flex;
  flex-direction: row-reverse;
  justify-content:space-between;
  align-items:center;
  .cart-invoice-input-label{
    direction:rtl;
    font-family: YekanRegular;
    width: 150px;
  }
  textarea{
    border: none;
    border-radius: 8px;
    font-family: YekanRegular;
    text-align: center;
    width: 100%;
    height: 100px;
  }
  input{
    border: none;
    border-radius: 8px;
    height: 40px;
    font-family: YekanRegular;
    text-align: center;
    width: 100%;
  }
}

.cart-invoice-input-btn{
  direction: rtl;
  font-family: YekanRegular;
  width: 150px;
  background-color: #8D89FD;
  color: white;
  border-radius: 8px;
  text-align: center;
  margin-left: 10px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
}


.cart-products-list{
  border: 1px solid lightgray;
  margin: 8px;
  border-radius: 12px;
  .header-row{
    div{
      font-family: YekanBold !important;
    }
  }
  .cart-products-row:first-child{
    border-top: none;
  }
  .cart-products-row{
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    border-top: 1px solid lightgrey;
    padding:2px 10px;
    div:first-child{
      border-left:none;
    }
    div{
      font-family: YekanRegular;
      direction: rtl;
      display: flex;
      align-items: center;
      justify-content: center;
      border-left: 1px solid lightgrey;
      flex:1;
    }
  }
}

.cart-invoice-btn{
  display: flex;
  justify-content: center;
  padding: 16px 0;
  .cart-invoice-submit{
    background-color: #6c67eb;
    color: white;
    font-family: YekanBold;
    border-radius: 8px;
    padding: 4px;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

//track-drawer

.track-drawer{

}

.track-drawer-title{
  display: flex;
  justify-content: center;
  margin: 16px 0;
  h5{
    font-family: YekanBold;
    font-size: 14px;
  }
}

.track-drawer-input{
  display: flex;
  justify-content: center;
  margin: 16px 0;
  input{
    font-size: 15px;
    font-family: YekanRegular;
    border: 1px solid lightgrey;
    width: 90%;
    border-radius: 12px;
    padding: 8px 4px;
    text-align: center;
  }
}

.track-drawer-hint{
  display: flex;
  justify-content: center;
  margin: 16px 0;
  span{
    font-family: YekanRegular;
  }
}

.track-drawer-state{
  display: flex;
  justify-content: center;
  margin: 16px 0;
  flex-direction: column;
  align-items: center;
  span{
    //border:1px solid lightgrey;
    width: 90%;
    padding: 8px 12px;
    font-size: 16px;
    border-radius: 12px;
    text-align: center;
    font-family: YekanBold;
  }
}

.track-drawer-state-send{
  background-color: mediumseagreen;
}
.track-drawer-state-packing{
  background-color: #6a9cfc;
}
.track-drawer-state-pending{
  background-color: #ffc877;
}
.track-drawer-state-cancel{
  background-color: #ff9eaf;
}

.track-drawer-state-description{
  margin: 0 24px;
  p{
    width: 100%;
    font-family: YekanRegular;
    text-align: center;
  }
}

.track-drawer-btn{
  display: flex;
  justify-content: center;
  span{
    width: 90%;
    padding: 12px;
    background-color: royalblue;
    color: white;
    font-family: YekanMedium;
    border-radius: 12px;
    text-align: center;
  }
}

.cart-done-image{
  display: flex;
  justify-content: center;
  padding: 20px 0;
}

.cart-done-hint{
  display: flex;
  justify-content: center;
  direction: rtl;
  margin: 0 30px;
  p{
    font-family: YekanMedium;
    align-items: center;
  }
}

.cart-done-track-code{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 12px;
  h5{
    font-family: YekanBold;
    font-size: 20px;
    margin: 0;
  }
  span{
    font-family: YekanMedium;
    background-color: royalblue;
    color: white;
    padding: 8px;
    border-radius: 8px;
  }
}
.cart-done-guid{
  display: flex;
  justify-content: center;
  direction: rtl;
  margin: 0 30px;
  p{
    font-family: YekanMedium;
    align-items: center;
    color: #096dd9;
  }
}

.track-drawer-support-mobile{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffdada;
  border-radius: 8px;
  margin: 12px 20px;
  padding: 8px;
  h5{
    font-family: YekanBold;
    font-size: 16px;
    margin:0;
  }
  span{
    font-family: YekanMedium;
    color: #3d3d3d;
    font-size: 13px;
    direction: rtl;
  }
}

.out-of-stock{
  border: 1px solid #999999;
  margin: 2px 0 15px 0;
  padding: 1px;
  font-family: 'YekanRegular';
  border-radius: 4px;
  width: 73px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}

.ant-message-custom-content{
  direction: rtl !important;
}

.shop-address{
  background-color: #F5F2F2;
  margin: 8px 24px;
  border-radius: 8px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  h5{
    direction: rtl;
    font-family: YekanBold;
  }
  p{
    direction: rtl;
    font-family: YekanRegular;
  }
}


.employee-label{
  font-family: YekanRegular;
  font-size:12px;
  display: flex;
  justify-content: center;
}






//products

.employee-card-swiper-product-card-wrapper {
  width: 110px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin-right:26px;

}

.employee-card-swiper-product-card{
  //background-color:#fdfdfd;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 14px;
  .employee-card-swiper-product-card-image{
    position: relative;
    width: 160px;
    height: 70px;
    border-radius: 8px 8px 0 0;
    img{
      width: 100%;
      height: 100%;
      border-radius: 8px 8px 0 0;
      object-fit: cover;
    }
  }
  .employee-card-swiper-product-card-price{
    height: 15px;
    text-align: center;
    span{
      font-family: YekanBold;
      font-size: 15px;
    }
  }
  .employee-card-swiper-product-card-discount{
    height: 20px;
    text-align: center;
    span{
      font-family: YekanBold;
      font-size: 12px;
      text-decoration: line-through;
      color: red;
    }
  }

  .employee-card-swiper-product-card-name{
    font-family: YekanBold;
    font-size: 13px;
    padding: 5px 0 8px;
    color: gray;
    text-align: center;
    height: 50px;
    margin: 0 5px;
    direction: rtl;
  }

  .product-swiper-product-card-add-btn{
    padding-bottom: 15px;
    img{
      width:25px
    }
  }

}

.employee-card-image-swiper-placeholder{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  .anticon{
    font-size:30px;
    color: gray;
  }
}


.employee-card-list{
  display: flex;
  flex-direction: column;
  padding-top: 12px;
  .employee-card-list-item{
    display: flex;
    align-items: center;
    margin: 4px 0;
    .label-text{
      font-size:12px;
      font-family:YekanMedium;
      flex: 1;
    }
    .bullet{
      width: 10px;
      height: 10px;
      background-color: #8D89FD;
      border-radius: 50%;
      margin: 0 4px;
    }
  }
}


.employee-card-label{
  margin: 8px;
  span{
    font-family:yekanRegular;
    color: #f84a4a;
    font-size:12px
  }
}

.employee-card-btn{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px 0 0 0;
  span{
    background-color: #9E9AFF;
    font-family: YekanBold;
    width: 90%;
    color: white;
    border-radius: 8px;
    padding: 4px 12px;
    text-align: center;
  }
}

.image-mask{
  background-color: rgba(255, 255, 255, 0.72);
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 8px 8px 0 0;
  display: flex;
  font-family: YekanBold;
  justify-content: center;
  align-items: center;
  color: #000000;
  font-size: 18px;
  text-align: center;
  padding: 12px;
  direction: rtl;
  font-weight: bold;
}


.employee-card-list{
  display: flex;
  flex-direction: column;
  min-height: 100px;
  width: 90%;
  padding-top: 12px;
  .subscription-card-list-item{
    display: flex;
    align-items: center;
    margin: 4px 0;
    .label-text{
      font-size:12px;
      font-family:YekanMedium;
      flex: 1;
    }
    .bullet{
      width: 10px;
      height: 10px;
      background-color: #8D89FD;
      border-radius: 50%;
      margin: 0 4px;
    }
  }
}

.employee-package-image-mask{
  background-color: #cdc7ed;
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 8px 8px 0 0;
  display: flex;
  font-family: YekanBold;
  justify-content: center;
  align-items: center;
  color: #000000;
  font-size: 15px;
  direction: rtl;
  font-weight: bold;
  text-align: center;
  padding: 0 12px;
}

.employee-image{
  width: 78%;
  height: 150px;

  //margin-bottom: 12px;

  img{
    width: 100%;
    height:100%;
    border-radius: 8px;
    object-fit: cover

  }
}

.employee-info{
  //background-color: #ff6e4d;
  padding: 3px 16px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width:125px;
  .employee-name{
    color: white;
    font-size: 13px;
  }
  .employee-label{
    color: white;
  }
}

.employee-name{
  font-family: YekanBold;
  direction: rtl;
}
.employee-label{
  font-family: YekanRegular;
  color: gray;
  direction: rtl;
}
